import React from 'react'
import { Link } from 'gatsby'
import ReactWOW from 'react-wow'
import bannerImg3 from '../../assets/images/453.jpg'
import ContactForm from './ContactForm'




const MainBanner = () => {
    return (
        <div className="digital-ebook-banner">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-12">
                        <div className="banner-wrapper-content">
                            <span className="sub-title">Obtenez gratuitement une copie de notre ebook</span>
                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <h2>11 erreurs fatales qui empêche votre site d'apparaitre en premier sur Google!</h2>
                                <br/>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <p>Pour une durée limitée, nous vous envoyons par mail une copie gratuite de notre rapport: "11 erreurs fatales qui empêche votre site d'apparaitre en premier sur Google". Ce rapport vous explique en détails toutes les raisons pour lesquelles votre site internet ne figure pas en première page des résultats Google.</p>
                                <p></p>
                                <p>A lire absolument pour ceux qui ne souhaitent plus apparaitre à la troisième page de résultats et passer à coté des centaines de clients qui naviguent sur le web tous les jours. Recevez le maintenant.</p>
                                {/* <p><i className="flaticon-tick"></i> Hébergement rapide et sécurisé</p> */}
                                
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box">
                                    {/* <Link to="/contact" className="default-btn">
                                        <i className="flaticon-right"></i>
                                        Contactez-nous maintenant!
                                        <span></span>
                                    </Link> */}
                                    <ContactForm />
                                </div>
                            </ReactWOW>
                        </div>
                    </div>

                    <div className="col-lg-7 col-md-12">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="banner-wrapper-image">
                                <img src={bannerImg3} alt="banner" />
                            </div>
                            {/* <Lottie
                                options={defaultOptions}
                                height="80%"
                                width="80%"
                            /> */}
                        </ReactWOW>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainBanner

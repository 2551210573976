import React from 'react'
import Layout from "../components/App/Layout"
import MainBanner from '../components/Ebook/MainBanner'
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ServiceSeoContent from '../components/ServiceDetails/ServiceSeoContent'
import StartProject from '../components/Common/StartProject'
 
const Ebook = () => {
    return (
        <Layout>
            <MainBanner />
            {/* <PageBanner
                pageTitle="SEO & Marketing" 
                homePageText="Accueil" 
                homePageUrl="/" 
                activePageText="SEO & Marketing" 
            />
            <ServiceSeoContent /> */}
            <StartProject />
            <Footer />
        </Layout>
    );
}

export default Ebook